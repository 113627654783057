<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :fields="fields"
      :row-data="row_data"
      post-api="/bed/AddBed"
      finish-router="bed"
      permission="Bed_Create"
    >
      <template #facility="scope">
        <SlotFacility :row-data="scope.rowData" :company-id="scope.rowData.companyID" :facility-id="scope.rowData.facilityID" :is-edit="scope.isEdit" />
      </template>

      <template #floor="scope">
        <SlotFloor :row-data="scope.rowData" :facility-id="scope.rowData.facilityID" />
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData" :floor-id="scope.rowData.floorID" />
      </template>

      <template #bed_type="scope">
        <SlotBedType :row-data="scope.rowData" :facility-id="scope.rowData.facilityID" />
      </template>

    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/bed/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import SlotFacility from "@/views/bed/SlotFacility";
import SlotFloor from "@/views/bed/SlotFloor";
import SlotRoom from "@/views/bed/SlotRoom";
import common from "@/common";
import SlotBedType from "@/views/bed/SlotBedType";


export default {
  name: "Add",
  components: {
    SlotBedType,
    SlotRoom,
    SlotFloor,
    SlotFacility,
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('bed') + ' - ' + common.getI18n('common.add'),
      fields,
      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: null,
        roomID: null,
        bedTypeID: null,
        isActive: true
      }
    }
  },
  created() {
    reloadFieldData()
  },
}
</script>

<style scoped>

</style>
